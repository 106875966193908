/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../api/axiosApi";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../utils/setAuth";
import { key } from "../../utils/config";
import { DangerRight, Success } from "../../api/toastServices";
import axios from "axios";

const initialState = {
  ticketRequestData: [],
  totalClientAdmin: 0,
  isLoading: false,
  total: null,
};

export const getTicketRequest = createAsyncThunk(
  "ticketRequest/getTicketRequests",
  async (payload) => {
    return apiInstance.get(
      `ticketRequest/getTicketRequests?start=${payload?.start}&limit=${payload?.limit}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&status=${payload?.status}&developer=${payload?.developer}&search=${payload?.search}`
    );
  }
);

export const ticketRequestAccept = createAsyncThunk(
  "/ticketRequest/handleTicketRequest",
  async (payload) => {
    return apiInstance.patch(
      `ticketRequest/handleTicketRequest?ticketRequestId=${payload?.data?.ticket}&type=${payload?.type}&developer=${payload?.developer}`
    );
  }
);

export const ticketRequestDeclined = createAsyncThunk(
    "/declinticketRequest/handleTicketRequest",
    async (payload) => {
      return apiInstance.patch(
        `ticketRequest/handleTicketRequest?ticketRequestId=${payload?.data?.ticket}&type=${payload?.type}`
      );
    }
  );

const ticketRequest = createSlice({
  name: "ticketRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTicketRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTicketRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ticketRequestData = action?.payload?.data;
      state.total = action?.payload?.total;
    });

    builder.addCase(getTicketRequest.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(ticketRequestAccept.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(ticketRequestAccept.fulfilled, (state, action) => {
      
      state.isLoading = false;
      if (action?.payload?.status) {
        state.ticketRequestData = state.ticketRequestData.filter(
          (item) => item?._id !== action?.payload?.data?._id
        );
        Success("Request Accepted Succesfully");
      } else {
        DangerRight(action.payload?.message);
      }
    });

    builder.addCase(ticketRequestDeclined.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(ticketRequestDeclined.pending, (state, action) => {
        state.isLoading = true;
      });
  
      builder.addCase(ticketRequestDeclined.fulfilled, (state, action) => {
       
        state.isLoading = false;
        if (action?.payload?.status) {
          state.ticketRequestData = state.ticketRequestData.filter(
            (item) => item?._id !== action?.payload?.data?._id
          );
          Success("Request Declined Succesfully");
        } else {
          DangerRight(action.payload?.message);
        }
      });
  
      builder.addCase(ticketRequestAccept.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export default ticketRequest.reducer;
