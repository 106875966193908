import Avtarone from '../../assets/images/avtar/one.png'
import Avtartwo from '../../assets/images/avtar/two.png'
import Avtarthree from '../../assets/images/avtar/three.png'
import Avtarfour from '../../assets/images/avtar/four.png'
import Avtarfive from '../../assets/images/avtar/five.png'
import Avtarsix from '../../assets/images/avtar/six.png'

import Avtar1 from '../../assets/images/clientImg/avtar1.png'
import Avtar2 from '../../assets/images/clientImg/avtar2.png'
import Avtar3 from '../../assets/images/clientImg/avtar3.png'
import Avtar4 from '../../assets/images/clientImg/avtar4.png'
import Avtar5 from '../../assets/images/clientImg/avtar6.png'
import Avtar6 from '../../assets/images/clientImg/avtar7.png'
import Avtar7 from '../../assets/images/clientImg/avtar8.png'
import Avtar8 from '../../assets/images/clientImg/avtar9.png'
import Avtar9 from '../../assets/images/clientImg/avtar10.png'
import Avtar10 from '../../assets/images/clientImg/avtar11.png'
import Avtar11 from '../../assets/images/clientImg/avtar12.png'
import Avtar12 from '../../assets/images/clientImg/avtar13.png'
import Avtar13 from '../../assets/images/clientImg/avtar14.png'
import Avtar14 from '../../assets/images/clientImg/avtar15.png'




// export const AvtarImg= [Avtarone,Avtartwo,Avtarthree,Avtarfour,Avtarfive,Avtarsix]
export const AvtarImg= [Avtar1,Avtar2,Avtar3,Avtar4,Avtar5,Avtar6,Avtar7,Avtar8,Avtar9,Avtar10,Avtar11,Avtar12,Avtar13,Avtar14]
