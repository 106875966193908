import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../../../extras/Button";
import { getAllDeveloper } from "../../../redux/slice/ticketSlice";
import { useDispatch, useSelector } from "react-redux";
import { ticketRequestAccept, ticketRequestDeclined } from "../../../redux/slice/ticketRequestSlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";

export default function TicketRequestDeclineDialog() {
  const dispatch = useDispatch();
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const handleSubmit = async (image) => {
   
      const getData = {
        ticket: dialogueData?._id,
      };
      let payload = {
        data: getData,
        type: 3,
      };
      let response = await dispatch(ticketRequestDeclined(payload)).unwrap();
      if (response?.data?.status) {
        dispatch(closeDialog({ type: "declineRequest" }));
      } else {
        dispatch(closeDialog({ type: "declineRequest" }));
      }
    }

  return (
    <div className="dialog DeveloperDialog TicketDialog">
      <div className="mainDiaogBox">
        <div className="dialogHead">
          <h6>Are you sure you want to decline request??</h6>
          <IoCloseSharp
            onClick={() => dispatch(closeDialog({ type: "ticket" }))}
          />
        </div>

        <div className="dialog-footer">
          <Button
            text={"Cancel"}
            className={"cancelButton"}
            onClick={() => dispatch(closeDialog({ type: "developer" }))}
          />
          <Button
            text={"Submit"}
            type={"button"}
            className={"submitButton"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
