import React, { useEffect, useState } from "react";
import Title from "../../extras/Title";
import { getTicket, reopenTicket } from "../../redux/slice/ticketSlice";
import Table from "../../extras/Table";
import AvtarImg from "../../../assets/images/AvtarImg.png";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Pagination from "../../extras/Pagination";
import Searching from "../../extras/Searching";
import { IoTrashSharp } from "react-icons/io5";
import { FontAwesomeIcon } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";
import { GrFormView } from "react-icons/gr";
import { GoIssueReopened } from "react-icons/go";
import { openDialog } from "../../redux/slice/dialogueSlice";
import { FiPlus } from "react-icons/fi";
import { baseURL } from "../../utils/config";
import Button from "../../extras/Button";
import DeveloperDialogue from "./dialogue/DeveloperDialogue";
import { warning } from "../../utils/Alert";
import { IoIosCopy } from "react-icons/io";
import TicketDialogue from "./dialogue/TicketDialogue";
import TicketView from "./dialogue/TicketView";
import { Success } from "../../api/toastServices";
import Analytics from "../../extras/Analytics";
import TicketDialogueDeveloperEdit from "./dialogue/TicketDialogueDeveloperEdit";
import Select from "react-select";
import { getAllDeveloper } from "../../redux/slice/developerSlice";
import { getTicketRequest } from "../../redux/slice/ticketRequestSlice";
import { FcAcceptDatabase } from "react-icons/fc";
import TicketRequestDialog from "./dialogue/TicketRequestDialog";
import { MdCancel } from "react-icons/md";
import TicketRequestDeclineDialog from "./dialogue/TicketRequestDeclineDialog";
import AcceptedRequest from "./AccpetedRequest";

export default function TicketRequest() {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("All");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("All");
  const [status, setStatus] = useState(1);
  const [statusLabel, setStatusLabel] = useState("All");
  const [devLabel, setDevLabel] = useState("All");
  const [dev, setDev] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { ticketRequestData, total } = useSelector(
    (state) => state.ticketRequest
  );

  const dispatch = useDispatch();

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", AvtarImg);
    });
  });

  const TruncatedText = ({ text, maxLength }) => {
    const [isTruncated, setIsTruncated] = useState(true);
  
    const toggleTruncated = () => {
      setIsTruncated(!isTruncated);
    };
  
    const addLineBreaks = (fullText) => {
      // Add <br/> after every 100 characters
      return fullText.replace(/(.{100})/g, "$1<br/>");
    };
  
    const formattedText = isTruncated
      ? `${text.slice(0, maxLength)}...`
      : addLineBreaks(text);
  
    return (
      <span>
        <span
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
        <span
          onClick={toggleTruncated}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isTruncated ? " Show More" : " Show Less"}
        </span>
      </span>
    );
  };

  useEffect(() => {
    setData(ticketRequestData);
  }, [ticketRequestData]);

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      startDate: startDate,
      endDate: endDate,
      status: status,
      search: search,
      developer: dev,
    };
    dispatch(getTicketRequest(payload));
    const startLimit = {
      start: 1,
      limit: 100,
    };
  }, [page, rowsPerPage, startDate, endDate, search, status, dev]);

  const options = [
    { value: "All", label: "All" },
    { value: 1, label: "Open" },
    { value: 2, label: "Closed" },
    { value: 3, label: "Reopened" },
  ];

  //   const allDev = developerAdminData.map((dev) => ({label:dev.name,value:dev._id}))

  //   allDev.unshift({label:'All',value:'All'})

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setRowsPerPage(value);
  };
  const handleFilterData = (filteredData) => {
    setSearch(filteredData);
  };

  const hadnleCopyText = (ticketId) => {
    navigator.clipboard.writeText(ticketId);
    Success("Ticket copied");
  };

  const handleReopenTicket = (row) => {
    const payload = {
      clientId: row?.client?._id,
      id: row?._id,
    };
    dispatch(reopenTicket(payload));
  };

  const handleDelete = (id) => {
    warning("Delete").then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteDeveloper(id));
      }
    });
  };

  const handleStatus = (e) => {
    setStatus(e.value);
    setStatusLabel(e.label);
  };

  const handleDev = (e) => {
    setDev(e.value);
    setDevLabel(e.label);
  };

  const hadnleViewTicket = (data) => {
    dispatch(openDialog({ type: "ticketView", data: data }));
  };

  const handleAcceptRequest = (data) => {
    dispatch(openDialog({ type: "acceptRequest", data: data }));
  };

  const handleDeclineRequest = (data) => {
    dispatch(openDialog({ type: "declineRequest", data: data }));
  };

  const ticketTable = [
    {
      Header: "No",
      body: "no",
      Cell: ({ index }) => (
        <span>{(page - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Ticket Id",
      body: "ticketId",
      Cell: ({ row }) => (
        <div className="copyText">
          <span>{row?.ticketId}</span>
          {/* <Button
                        onClick={() => hadnleCopyText(row?.ticketId)}
                        bIcon={<IoIosCopy />}
                    /> */}
        </div>
      ),
    },
    {
      Header: "Client Name",
      body: "clientName",
      Cell: ({ row }) => <span>{row?.client?.name}</span>,
    },

    {
      Header: "Developer Name",
      body: "developerName",
      Cell: ({ row }) => <span>{row?.developer}</span>,
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span>{row?.appName}</span>,
    },
    {
      Header: "Issue Description",
      body: "issueDescription",
      Cell: ({ row }) => (
        <TruncatedText
          text={row?.issueDescription?.replace(/<\/?[^>]+(>|$)/g, "")}
          maxLength={30}
        />
      ),
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) => (
        <div className="statusButton d-flex justify-content-center">
          {status === 1 ? (
            <span className="openTicket  badge">Pending</span>
          ) : status === 2 ? (
            <span className="openTicket  badge">Accepted</span>
          ) : (
            <span className="openTicket  badge">Declined</span>
          )}
        </div>
      ),
    },

    status == 1 && {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            className={"accept"}
            style={{ backgroundColor: "rgb(29 104 46)" }}
            onClick={() => handleAcceptRequest(row)}
            bIcon={<FcAcceptDatabase />}
          />

          <Button
            className={"declined"}
            style={{ backgroundColor: "rgb(29 104 46)" }}
            onClick={() => handleDeclineRequest(row)}
            bIcon={<MdCancel />}
          />
        </div>
      ),
    },

    {
      Header: "Date",
      body: "Date",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM, YYYY")}</span>
      ),
    },
  ];

  return (
    <div className="TicketPage">
      <Title name={"Ticket Request"} total={total} totalShow={true} />
      <div className="row mt-3 mb-1">
        <div className="col-12 col-md-8 d-flex align-items-center anlyticsButton">
          <Analytics
            dayAnalyticsShow={true}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />

          <div className="d-flex justify-content-between ms-2" style={{border : "1px solid #339993" , borderRadius : "30px" , padding : "5px"}}>

        
            <Button
              text={"Pending"}
              className={"newbuttonone me-2"}
              style={{ opacity: status === 1 ? 1 : 0.5 , }}
              onClick={() => setStatus(1)}
            />
            <Button
              text={"Accepted"}
              className={"newbuttonone me-2"}
              style={{
                opacity: status === 2 ? 1 : 0.5,
              }}
              onClick={() => setStatus(2)}
            />
            <Button
              text={"Declined"}
              className={"newbuttonone"}
              style={{
                opacity: status === 3 ? 1 : 0.5,
              }}
              onClick={() => setStatus(3)}
            />

          </div>

       

          {/* <Select
            className="ms-3 select-status"
            value={dev}
            onChange={(e) => handleDev(e)}
            options={allDev}
            placeholder={devLabel}
            defaultValue={allDev[0]?.value} 
            components={{ DropdownIndicator: CustomIndicator2 }}

          /> */}
        </div>
        <div className="col-12 col-md-4 mt-2">
          <Searching
            type="server"
            data={ticketRequestData}
            button={true}
            setData={setSearch}
            allData={ticketRequestData}
            serverSearching={handleFilterData}
          />
        </div>
      </div>
      <div className="mt-0">
        <div className="row">
          <div className="col-6"></div>
        </div>
        <Table
          data={data}
          mapData={ticketTable}
          serverPerPage={rowsPerPage}
          serverPage={page}
          type={"server"}
        />
        <Pagination
          type={"server"}
          activePage={page}
          rowsPerPage={rowsPerPage}
          userTotal={total}
          setPage={setPage}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
        {dialogueType === "ticket" && <TicketDialogue />}
        {dialogueType === "ticketView" && <TicketView />}
        {dialogueType === "acceptRequest" && <TicketRequestDialog />}
        {dialogueType === "declineRequest" && <TicketRequestDeclineDialog />}
      </div>
    </div>
  );
}
